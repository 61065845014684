import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>


        <hr />

        <div className="content">

                    {/* <p class="title is-6">Izcelsme</p>
                    <p>{props.native}</p> */}

                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div>



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [
        // {
        //     title: "Vaska puķe",
        //     image: "https://i.imgur.com/BMDy6UXb.png",
        //     native: "Austrālija",
        //     care: "Apkārt ziediem ir maza vaska kārtiņa, kas arī palīdz tām ilgi palikt ziedošām un skaistām",
        //     description: "Mazas skaistas puķītes, šoreiz kombinējām gan baltas, gan maigi rozā krāsā. Šie ziedi vāzē var stāvēt veselu mūžību, ne velti tāds nosaukums."
        // },
        {
            title: "Mimosa",
            image: "https://i.imgur.com/hqfRAJDb.jpg",
            native: "Austrālija",
            care: "Mimosas ir skaistas, bet mazliet cimperlīgas to kopšanas ziņā  - ūdens vāzē tām ir jāmaina katru dienu, kā arī kātiņi no apakšas ir jāapgriež katru reizi. Mimosām, vāzē patīk, ja tās apsmidzina ar ūdeni vai arī, ja mājās strādā gaisa mitrinātājs, tad ziedu kompozīciju var novietot netālu no tā.",
            description: "Koši dzeltenie zariņi rada krāsu eksplozija mūsu acīm, kamēr Latvijā mēs vēl gaidām pavasari. Tās patiesība ir maza koku ziedi. Itālijā tās ir Sieviešu diena simbols. Mimosas arī izžāvēt, tehnika plaši aprakstīta internetā."
        },
        {
            title: "Tulpe",
            image: "https://i.imgur.com/2KsM2qmb.jpg",
            native: "Āzija",
            care: "Tulpes saglabāsies svaigas daudz ilgāk, ja katru dienu mainīsi vāzē ūdeni un apgriezīsi to stublājus. Tām nepatīk liels karstums, neturi tās pie radiatoriem un tiešos saules staros.",
            description: "Tulpes ir pavasara vēstneses un karalienes. Leģendāras ar savu kādreiz augsto cenu, tulpju mānijas laikā, kad tās maksāja vairāk kā nekustamais īpašums. Tās tagad iepriecina ne tikai pavasarī, bet arī jebkurā citā sezonā. Kopā 3000 dažādu tulpju veidus var atrast jebkurā iedomājamā krāsā."
        },
        {
            title: "Neļķe",
            image: "https://i.imgur.com/Q91LSVnb.jpg",
            native: "Eiropa",
            care: "Noteikti nevajadzētu blakus neļķēm turēt augļus. Ja neļķes mājās tiek turētas 18 grādu temperatūrā, tās vāzē var stāvēt līdz pat 3 nedēļām.",
            description: "Angliskais nosaukums Carnation. Tās pēdējos gados ir nepamatoti atstātas ēnā. Carnation tika uzskatītas par valdzinājuma, atšķirības un mīlestības ziediem un, ja mēs kārtīgāk ieskatāmies zieda tekstūrā, tā ir tik delikāta, kompleksa un fascinējoša. Neļķe ļoti atsvaidzina un padara interesantu, un daudz dziļāku jebkuru ziedu kompozīciju."
        },
        {
            title: "Hiacinte",
            image: "https://i.imgur.com/FlR8Meub.jpg",
            native: "Vidusjūra un Tuvie austrumi",
            care: "Negriez hiacinšu apakšas! Tām jāsaglabā apakšējā kāta daļa, lai tas turpinātu augt vāzē. Tā Hiacintes vāzē var ilgt līdz pat 12 dienām.",
            description: "Pirmā asociācija ar hiacintēm ir to brīnišķīgā smarža. Hiacintes jūsu kompozīcijā vēl ir pumpuros, no citām puķēm tās atšķiras ar to, ka tām nav problēmu arī augt un izziedēt esot vāzē. Tikai uzmani, lai tās atveroties un kļūstot smagākas neapgāž vāzi."
        },
    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px"  />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px"  />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">

                    <h2 class="title is-3" style={{ color: '#eee' }}>Instrukcija</h2>

                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/1367318f-8fdd-4848-bd30-96c881cb4f86/playlist.m3u8"
                        captions="/titles/002.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <h2 class="title is-3">Sveiks saulainais, Pavasari!</h2>

                    <p>Šī kompozīcija ienesīs pavasara smaržu un saulainumu Tavās mājās. Šodien ziedu kompozīcijai labāk derēs platāka vāze, jo kopā esam sagatavojuši 23 ziedus un daudz saulainās mimosas zarus.</p>

                    <ol>
                        <li>Vāzē lej ūdeni istabas temperatūrā </li>
                        <li>Pievieno ziedu barību, 1 paciņa* uz 0,5l ūdens, samaisi</li>
                        <li>Obligāti, visiem, izņemot hiacintes, ziedu stublājiem nogriez 3cm no apakšas, lai tie spētu uzsūkt ūdeni un ilgāk paliktu svaigi</li>
                        <li>Kārto ziedus vāzē un saīsini ziedu stublājus, izņemot hiacintes, ja nepieciešams (tas atkarīgs no izmantotās vāzes augstuma)</li>
                    </ol>

                    <p>Atlasītie ziedi kompozīcijai ir izvēlēti, domājot par to ilgmūžību. Daļa ziedu piegādājot vēl var būt pumpuros, pēc pāris dienām istabas temperatūrā pumpuri atvēries. Ņemot vērā ziedu īpašības, mēs zinām, ka Tulpes būs pirmās, kas dabiski novecos - tas nekas. Kad tas notiek, izņemam tās no kompozīcijas. Neļķes, Mimosas, Hiacintes vāzē būs svaigas ilgāk.</p>

                    <p>Īpaši labi ziedi stāvēs, ja to ūdeni mainīsi reizi 2-3 dienās. Kā to pareizi darīt? </p>

                    <ol>
                        <li>Noskalo ziedu kātus</li>
                        <li>Izmazgā vāzi</li>
                        <li>Iepildi istabas temperatūras ūdeni ar ziedu barību</li>
                        <li>Ziediem nogriez 3cm no stublāju apakšas</li>
                    </ol>

                    <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Messenger čatā, loga stūrī!</p>

                    <p>*Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja  koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>

                    
                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-half">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                        />

                                </div>
                            )
                        })}
                        {/* <div className="column is-one-third">
                            <Stem
                                title="Āzijas gundega jeb Ranunculus"
                                latin="Persian Buttercup"
                                image="https://i.imgur.com/OvIOGrxb.jpg"
                                native="Āzija"
                                care="Ik pēc 2 dienām, kad maini ūdeni vāzē, nogriez tām kātiņus aptuveni 2 cm no apakšas, 45 grādu leņķī, lai zieds varētu uzsūkt vajadzīgo ūdeni un tajā esošās barības vielas. Vāzē tās var dzīvot līdz pat 10-12 dienām."
                                description="Šoreiz izvēlējāmies aprikožu krāsas Ranunculus Cloony Hanoi un romantisko Ranunculus Elegance pink šķirnes ziedus." />

                        </div>
                        <div className="column is-one-third">                    
                        <Stem
                            title="Lefkoja jeb matiolas"
                            latin="Mathiola"
                            image="https://i.imgur.com/4l4p5ibb.jpg"
                            native="Vidusjūras apgabals"
                            care="Saņemot, tās var būt nedaudz bēdīgas. Dažu stundu laikā tās atgūsies. Ik pēc 2 dienām, kad maini ūdeni vāzē, nogriez tām kātiņus aptuveni 2 cm no apakšas, 45 grādu leņķī, lai zieds varētu uzsūkt vajadzīgo ūdeni un tajā esošās barības vielas."
                            description="Šoreiz Tev piegādājām salīdzinoši maz izziedējušas lefkojas, lai tās Tev vāzē stāvētu vēl ilgāk." />
                        </div>
                        <div className="column is-one-third">                    
                        <Stem
                            title="Lizante"
                            image="https://i.imgur.com/YzJZNNYb.jpg"
                            native="Dienvidamerika"
                            care="Ik pēc 2 dienām, kad maini ūdeni vāzē, nogriez tām kātiņus aptuveni 2 cm no apakšas, 45 grādu leņķī, lai zieds varētu uzsūkt vajadzīgo ūdeni un tajā esošās barības vielas. Šie ziedi vāzē var ilgt līdz pat 14 dienām, ja regulāri tiek mainīts ūdens un dotas papildus ziedu barības vielas."
                            description="Šoreiz izvēlējāmies baltas krāsas ar lokainām maliņām, kas tāpēc izceļas ar savu faktūru un vizuāli ļoti atsvaidzina ziedu kompozīciju." />
                        </div>
                        <div className="column is-one-third">                    
                        <Stem
                            title="Roze"
                            image="https://i.imgur.com/Po5VN81b.jpg"
                            native="Āzija"
                            care="Ik pēc 2 dienām, kad maini ūdeni vāzē, nogriez tām kātiņus aptuveni 2 cm no apakšas, 45 grādu leņķī, lai zieds varētu uzsūkt vajadzīgo ūdeni un tajā esošās barības vielas. Rozes ilgst līdz vienai nedēļai."
                            description="Izvēlējāmies rozes Aqua ar atsvaidzinošo krāsu. Piegādājot, ziedi vēl ir nelieli, bet pēc pāris dienām tie atvērsies." />
                        </div>
                        <div className="column is-one-third">                    
                        <Stem
                            title="Brunia Albiflora"
                            image="https://i.imgur.com/nxNozWpb.jpg"
                            native="Dienvidāfrika"
                            care="Izžavējot, tai vajadzētu palikt gaiši pelēkā krāsā. Un to saglabājot, tā lieliski derēs arī nākamājam adventes vainagam."
                            description="Ļoti labi papildina kompozīcijas apjomu, kā arī tās kokainā struktūra ļauj to izžāvēt, kad pārējie ziedi jau vāzē novecojuši. Tad tā pati kļūst par zvaigzni sauso ziedu kompozīcijās." />
                        </div>
                        <div className="column is-one-third">                    
                        <Stem
                            title="Vaska puķe"
                            image="https://i.imgur.com/BMDy6UXb.png"
                            native="Austrālija"
                            care="Apkārt ziediem ir maza vaska kārtiņa, kas arī palīdz tām ilgi palikt ziedošām un skaistām"
                            description="Mazas skaistas puķītes, šoreiz kombinējām gan baltas, gan maigi rozā krāsā. Šie ziedi vāzē var stāvēt veselu mūžību, ne velti tāds nosaukums." />
                        </div>
                        <div className="column is-one-third">                    
                        <Stem
                            title="Eikalipts"
                            image="https://i.imgur.com/v4Ldp1Pb.png"
                            native="Āzija"
                            care="Eikalipts vāzē ilgs aptuveni 2 nedēļas"
                            description="Līdzīgi kā citiem ziediem, arī eikalptam ik pa 5-6 dienām nogriežam kātiņu aptuveni 3 centimetrus no apakšas, lai tas labāk spētu padzerties ūdeni." />
                        </div> */}

                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>
                            {/* <p>
                                <a class="button is-info" href="https://www.facebook.com/sharer/sharer.php?u=https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi">Publicēt saiti Facebook</a>
                            </p> */}
                            {/* 
                            <h2 class="title is-3">-10% Tev, -10% Tavam draugam</h2>

                            <p>Ja esi apmierināts ar ziediem, aicinām padalīties ar Taviem draugiem un draudzenēm. Tāpēc dalies ar kodu, ko saņēmi e-pastā.</p> */}

                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
